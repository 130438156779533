@import url('https://fonts.googleapis.com/css?family=Italiana');
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&family=Old+Standard+TT&display=swap");

.Homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FAF1EF;
}

.front-display {
    position: relative;
    height: auto;
    max-height: calc(100vh - 112px);
}

.front-image {
    width: 100vw;
    height: 100%;
    max-height: calc(100vh - 112px);
    aspect-ratio: 2.2;
    object-fit: cover;
    object-position: top;
}

.front-text-container {
    position: absolute;
    width: 100%;
    height: 30.2%;
    bottom: 0px;
    left: 0px;

    background: linear-gradient(180deg, rgba(162, 133, 152, 0) 0%, rgba(162, 133, 152, 0.71) 44.27%, #A28598 100%);
}

.front-text {
    position: absolute;
    width: fit-content;
    height: fit-content;
    left: 3.05%;
    bottom: 5.79%;
    margin: 0;

    font-family: 'Italiana';
    font-style: normal;
    font-weight: 400;
    font-size: 5.2vw;
    letter-spacing: -0.04em;

    display: flex;
    align-items: center;

    color: #FFFDF1;

}


/* Change height to vw? */
.review-display {
    width: 100%;
    height: 100%;

    max-width: 123.75em;
    margin: 6% 0 4% 0;
}

.redirect-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: nowrap;

    width: 100%;
    max-width: 123.75em;
    margin: 3% 0 1% 0;
}

.instagram-shoutout-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 123.75em;
    margin: 2.814% 0 0 0;
}

.instagram-posts-display {
    width: 100%;
    margin: 2.4em 0 0 0;
}

.scroll-to-top-container-homepage {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .review-display {
        height: 13.53vw;
    }
}

@media screen and (max-width: 480px) {
    .front-display {
        height: 15em;
    }

    .front-text {
        font-size: 7.67vw;
    }

    .review-display {
        height: 35.39vw;
        margin: 9% 0 9% 0;
    }

    .instagram-shoutout-display {
        margin: 0;
    }

    .instagram-posts-display {
        margin: 2.813em 0 4em 0;
    }

    .scroll-to-top-container-homepage {
        display: none;
    }
}