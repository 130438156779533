.current-posts-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.current-posts-wrapper {
    width: 100%;
    overflow: hidden;
}

.current-posts-content {
    display: flex;
    flex-direction: row;
    width: 100%;

    transition: all 350ms linear;
}

.current-posts-content > * {
    width: calc(100% / 3);
    flex-shrink: 0;
    flex-grow: 1;
}

.curPost {
    aspect-ratio: 1;
}

.postImg {
    height: 100%;

    aspect-ratio: 0.985;
    object-fit: cover;
    transition: ease-in 0.4s;
}

.postImg:hover {
    filter: brightness(75%);
    cursor: pointer;
}

.forward-posts-button, .backward-posts-button {
    border: none;
    cursor: pointer;
    appearance: none;
    background-color: inherit;
    transition: 0.5s
}

.forward-posts-button:hover, .backward-posts-button:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 480px) {
    .current-posts-display {
        width: 100%;
        height: 100%;
    }
    
    .curPost {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .active {
        height: 110%;
    }

    .inactive {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75%;
        filter: opacity(0.3);
    }

   .backward-posts-button, .forward-posts-button {
        display: none;
    }
}