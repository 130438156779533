.gallery-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    height: 100%;
    overflow: hidden;

}

.video-container {
    width: calc(100% - 96px);
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 764px) {
    .video-container {
        width: 94%;
    }
}

.video-div {
    aspect-ratio: calc(16/9);
}

.gallery-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-event-button {
    position: absolute;
    border: none;
    background: none;

    z-index: 1;
}

.button-image {
    height: auto;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.button-image:hover {
    transform: scale(1.1);
}

.back-button {
    left: 1.5vw;
}

.forward-button {
    right: 1.5vw;
}

.current-gallery-image-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2.5em 0 0 0;
    cursor: pointer;
}

.gallery-dot {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    background-color: #D9D9D9;
    margin: 0 0.5vw 0 0.5vw;
    transition: 0.5s;
}

.gallery-dot {
    transform: scale(1.2);
}

@media screen and (max-width: 1440px) {
    .gallery-primary-image {
        min-width: 35vw;
        max-width: 35vw;
    }
}

@media screen and (max-width: 1024px) {
    .gallery-primary-image {
        min-width: 40vw;
        max-width: 40vw;
    }
}

@media screen and (max-width: 800px) {
    .gallery-primary-image {
        min-width: 60vw;
        max-width: 60vw;
    }
}

/* added more gap to the gallery slider on mobile */
@media screen and (max-width: 480px) {
    .gallery-slider {
        gap: 4vw;
    }

    .button-image {
        height: 50px;
    }

    .current-gallery-image-dots {
        margin: 1.5em 0 0 0;
    }

    .gallery-dot {
        width: 7.38px;
        height: 7.38px;
    }

}