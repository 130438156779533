.confirmation-modal-backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #00000055;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation-button {
    display: flex;
    background-color: rgba(162, 133, 152, 1);
    color: rgba(252, 249, 234, 1);
    font-family: "Cormorant Garamond";
    font-size: 1.25em;
    font-weight: 700;
    width: 138px;
    height: 47px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    font-style: bold;
    text-decoration: none;
    box-shadow: 0px 4px 4px 0px rgba(227, 221, 221, 0.82);
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
}

.confirmation-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    width: 500px;
    height: 400px;
    margin: auto;
    background-color: white;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));
    padding: 32px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #A28598;
    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}