@import url('https://fonts.googleapis.com/css?family=Italiana');
@import url('https://fonts.googleapis.com/css?family=Cormorant Garamond'  );


:root {
	--speed: 500ms;
}
.MobileNav {
	width: 100%;
	max-height:100%;
	overflow: hidden;
	display:none;
}

.dropdown-menu {
	height: fit-content;
	position: absolute;
	top: 100px;
	border-radius: var(--border-radius);
	transition: var(--speed) ease;
}

.gallery-menu::before {
	content: '';
	position: absolute;
	top: -5px;
	right: 20px;
	max-height: 0;
	height: 20px;
	width: 20px;
	background: var(--secondary-bg);
	transition: max-height 0.15s ease-out;
}

.services-menu.active {
	opacity: 1;
	visibility: visible;
	max-height: 500px;
	transition: max-height var(--speed) ease;
	width: 100vw;
}

.dropdown-menu::before {
	content: '';
	position: absolute;
	top: -5px;
	right: 20px;
	max-height: 20px;
	width: 20px;
	background: var(--secondary-bg);
	transform: rotate(45deg);
	transition: var(--speed) ease;
}

.dropdown-menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: var(--speed) ease;
	width: 100vw;
	box-shadow: 0 0 0 9999px rgba(0,0,0,.5);
}

.dropdown-menu.inactive {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: var(--speed) ease;
}

.menu-trigger img {
	display: none;
	height: fit-content;
	position: absolute;
	top: 1.8em;
	/* right: 20px; */
	width: 60px;
	left: 2em;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	transition: var(--speed) ease;
	
	/* background-color: #fff; */
}

.dropdown-menu .main-category{
	display: block;
	padding: 20px 0;
	border-top: 1px solid white;
	background: #A28598;
	left: 10px;
	font-family: 'Cormorant Garamond';
	font-style: normal;
	font-weight: 700;
	font-size: 26px;
	color: white;
	text-align: left;
	padding-left: 2em;
	transition: var(--speed) ease;
}

.dropdown-menu .subcategory {
	display: block;
	padding: 20px 0;
	background: #A28598;
	left: 10px;
	/* width: 100vw; */
	font-family: 'Cormorant Garamond';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	text-align: left;
	padding-left: 4em;
	transition: var(--speed);
}

.dropdown-menu ul li a{
	color: white;
	transition: var(--speed) ease;
}

a {
	text-decoration: none;
	transition: var(--speed) ease;
}

ul {
	margin: 0 auto;
	padding: 0;
	transition: var(--speed) ease;
}

.active {
	text-decoration: none;
}

.dropdown-menu ul li:hover a{
	cursor: pointer;
}

.dropdown-menu ul li:hover img{
	opacity: 1;
	cursor: pointer;
}

.dropdownItem{
	display: flex;
	margin: 0 auto;
	width: 100vw;
	transition: var(--speed) ease;
}

.dropdownItem a {
	transition: var(--speed);
}

@media(max-width: 1024px) {
	.MobileNav {
		display: block;
	}
	.menu-trigger img {
		display: flex;
	}
}