@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.containerServ {
	display: flex;
	justify-content: center;
	text-align: left;
	margin-bottom: 0px;
}

#image {
	width: 39vw;
	max-width: 615px;
	height: 39vw; 
	max-height: 503px;
}

#actualImg {
	width: 42vw;
	max-width: 615px;
	height: 75%;
	aspect-ratio: 1;
	object-fit: cover;
	object-position: 0 0;
}

.arrow {
	font-size: 2em;
	cursor: pointer;
}

.description {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: rgb(250,242,239);
	width: 100vw;
	height: 50vw;
	max-height: 671px;
}

#texts {
	background: rgba(162, 133, 152, 1);
	width: 42vw;
	max-width: 615px;
	height: 39vw; 
	max-height: 503px;
}

#scrollText {
	width: 100%;
	height: 55%;
	overflow-y:scroll;
	margin-bottom: 20px;
}

#servTitle {
	margin-left: 9%;
	margin-bottom: 20px;
	color:rgba(252, 249, 234, 1);
	font-family: "Cormorant Garamond";
	font-size: 3em;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: 0.01em;
	text-align: left;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.servDesc {
	margin-left: 9%;
	width: 84%;
	color: rgba(252, 249, 234, 1);
	font-family: "Cormorant Garamond";
	font-size: 1.188em;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: 0.01em;
	text-align: left;
}

#contactButton {
	display: flex;
	background-color: rgba(252, 249, 234, 1);
	color: rgba(162, 133, 152, 1);
	font-family: "Cormorant Garamond";
	font-size: 1.25em;
	font-weight: 700;
	width: 27%;
	height: 11%;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	margin-left: 9%;
	font-style: bold;
	text-decoration: none;
}

#recom {
  display: flex;
}

@media screen and (max-width: 900px) {
	#servTitle {
		font-size: 2em;
		margin-bottom: 5px;
	}

	#contactButton {
		font-size: 1em;
	}
}

#scrollText::-webkit-scrollbar {
	-webkit-appearance: none;
}

#scrollText::-webkit-scrollbar:vertical {
	width: 11px;
}

#scrollText::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #634F5D;
}
#scrollText::-webkit-scrollbar-track {
	background-color: #7B6174;
 	border-radius: 8px;

}

#scrollText::-webkit-scrollbar-corner {
	background-color: rgba(162, 133, 152, 1);
}