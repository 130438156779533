.image-container {
    position: relative;
    width: 19%;
    aspect-ratio: 0.9;

    margin-left: 1.9%;
    margin-right: 1.9%;
}

.image-background {
    position: relative;
    width: 86%;
    height: 79.37%;
    
    margin-left: 14%;
    margin-top: 20.63%;
    background: #A28598;
    box-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.25);
    border-radius: 0.625em;
}

.image-text {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    margin: 0px 0.4em 0.25em 0px;

    font-family: 'Cormorant Garamond';
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    text-align: right;

    color: #FCF9EA;

    text-shadow: 0px 0.25em 0.25em rgba(0, 0, 0, 0.25);
}

.image-redirect {
    position: absolute;
    object-fit: cover;
    width: 86%;
    height: 79.37%;
    top: 0;
    left: 0;

    filter: drop-shadow(0px 0.25em 0.25em rgba(0, 0, 0, 0.25));
    border-radius: 0.625em;
    transition: 0.5s;
}

.image-redirect:hover{
    transform: scale(1.1);
}

.image-redirect:active {
    filter: brightness(85%);
}

@media screen and (max-width: 1024px) {
    .image-text {
        font-size: 2.25vw;
    }
}

@media screen and (max-width: 480px) {
    .image-container {
        width: 97.5%;
        aspect-ratio: 1.45;
    }
    
    .image-background {
        width: 100%;
        height: 80%;
        margin-top: 1.63%;
        margin-left: 0;

        box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.25);
    }

    .image-text {
        font-size: 4.25vw;
        text-align: center;
        margin: 0 0 1.35% 0;
    }

    .image-redirect {
        width: 100%;
        height: 69.69%;

        filter: none;
        object-position: 100% 33%;
        border-radius: 0.625em 0.625em 0 0;
    }
}