@import url('https://fonts.googleapis.com/css?family=Italiana');
@import url('https://fonts.googleapis.com/css?family=Cormorant Garamond');
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&display=swap');

.Contact {
	background: #FAF1EF;
	font-family: 'Italiana';
	width: 100%;
	height: fit-content;
	display: flex;
}

.Rightside-Screen {
	width: 56%;
	display: flex;
	background: white;
	justify-content: center;
}

.Leftside-Screen {
	background: #FAF1EF;
	width: 44%;
	display: flex;
}

.contact-wrapper {
	width: 100%;
}

.form-wrapper {
	font-family: 'Cormorant Garamond', serif;
	width: 100%;
	line-height: 70px;
	font-weight: 700;
	font-size: 22px;
	padding-top: 50px;
	padding-left: 48px;
	padding-right: 48px;
}

input[type=text],
input[type=email],
input[type=phone],
select,
textarea {
	width: inherit;
	font-family: 'Cormorant Garamond', serif;
	display: flex;
	height: 2.3em;
	border: 2px solid #000000;
	border-radius: 10px;
	font-size: 20px;
	font-weight: 300;
	padding-left: 0.5em;
	padding-right: 0.5em;
	box-sizing: border-box
}

input[type=date] {
	font-family: 'Cormorant Garamond', serif;
	display: block;
	padding-left: 0.5em;
	padding-right: 0.5em;
	width: inherit;
	height: 2.3em;
	border: 2px solid #000000;
	border-radius: 10px;
	font-size: 20px;
	font-weight: 300;
	box-sizing: border-box
}


::placeholder {
	text-decoration-color: lightgrey;
}

textarea {
	width: inherit;
	height: 19em;
	padding: 0.5em;
}

input[type=submit] {
	width: 6.2em;
	height: 2.5em;
	background: #A28598;
	color: white;
	border: none;
	border-radius: 10px;
	font-family: 'Italiana';
	font-weight: 300;
	font-size: 30px;
	line-height: 42px;
	float: right;
	transition: 0.4s;
	box-shadow: 5px 5px 5px grey;
	margin: 64px 0;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
	cursor: pointer;
	color: black;
	font-weight: 700;
	transform: translateY(-2px);
}

.contact-image {
	position: relative;
	padding-top: 5em;
	float: none;
	height: fit-content;
	text-align: center;
	display: flex;
	justify-content: center;
}

.contact-image img {
	width: 60%;
	margin: 0 auto;
	display: block;
	aspect-ratio: 0.8;
	object-fit: cover;
}

.lets-talk {
	width: 60%;
	margin: 0 auto;
	height: fit-content;
	font-family: 'Cormorant Garamond', serif;
	font-weight: 300;
	font-size: 24px;
	text-align: left;
	line-height: 2em;
	color: #000000;
	padding-top: 2em;
	transition: 0.2s;
}

.Contact ul {
	position: relative;
	list-style-type: none;
	margin: 0 auto;
	padding: 0;
	display: inline-flex;
}

.Contact ul li {
	display: flex;
	justify-content: space-between;
	align-items: left;
	padding: 0px 20px 0px 0px;
	transition: transform 0.5s;
}

.Contact ul li:hover {
	transform: translateY(-4px);
}

.Contact ul li a {
	width: 62px;
	height: 23px;
	line-height: 23px;
	text-decoration: none;
}

html {
	scroll-behavior: smooth;
}

body {
	position: relative;
}

.section {
	height: 100vh;
	background: #dedede;
	margin-bottom: 20px;
	font-size: 100px;
}

.scroll-container {
	position: fixed;
	bottom: 2em;
	right: 1em;
	transition: 0.3s;
	z-index: 999;
	width: fit-content;
	height: fit-content;
	overflow: hidden;
}

.scroll-container:hover {
	transform: translateY(-3px);
}

.scroll-container img {
	width: 4vw;
}

hr {
	display: none;
}

@media (max-width: 1050px) {
	.lets-talk {
		font-size: 20px;
	}
}

@media (max-width: 1024px) {
	.Contact {
		display: block;
	}

	.Leftside-Screen {
		padding-top: 0;
		width: 100vw;
		height: fit-content;
	}

	.Rightside-Screen {
		width: 100vw;
		height: fit-content;
		background: #FAF1EF;
	}

	.contact-image {
		padding-top: 0;
		width: fit-content;
	}

	.contact-image img {
		overflow: hidden;
		width: 100vw;
		height: 35em;
		object-fit: cover;
	}

	.lets-talk {
		text-align: center;
		width: fit-content;
		height: fit-content;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	hr {
		display: flex;
		width: 80vw;
		border-top: 2px solid darkgrey;
	}

	.form-wrapper {
		max-width: 100%;
		left: 10vw;
		width: 80vw;
	}

	textarea {
		height: 11em;
	}

	.form-wrapper {
		padding-top: 25px;
		line-height: 60px;
	}

	input[type=submit] {
		position: static;
		display: block;
		float: none;
		margin: 0 auto;
		margin-top: 2em;
		margin-bottom: 2em;
		text-align: center;
		font-weight: 700;
	}

	input[type=text],
	input[type=email],
	input[type=date],
	select,
	textarea {
		background: none;
		border-radius: 5px;
		border: 3px solid #000000;
	}

	.scroll-container {
		right: 0;
	}

	.scroll-container img {
		width: 8vw;
		float: right;
		margin: auto;
	}
}