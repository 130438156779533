/* latin */
@font-face {
    font-family: "Italiana";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/italiana/v16/QldNNTtLsx4E__B0XQmWaXx0xKVu.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
    font-family: "Cormorant Garamond";
    font-style: normal;
    font-weight: 400, 500;
    src: url(https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYqXtKky2F7g.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

.title {
    font-family: "Italiana";
    font-style: normal;
    font-weight: 400;
    font-size: 5.8vw;
    line-height: 151px;
    line-height: 6.4vw;
    text-align: center;

    color: #000000;
}

.highlightText{
    font-family: "Italiana";
    font-style: normal;
    font-weight: bold;
    font-size: 3.2vw;
    line-height: 75px;
    line-height: 3.7vw;
    color: #fcf9ea;
    padding-left: 3%;
    margin-bottom: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
}

.desc, .mobileDesc {
    font-family: "Cormorant Garamond";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    font-size: 2.2vw;
    line-height: 35px;
    line-height: 2.5vw;
    letter-spacing: -0.7px;
    color: #000000;
    margin-top: 3%;
}

.mobileDesc {
    display: none;
}

.highlightCont {
    background-color: #a28598;
    width: 120%;
}

.loveHighlightCont {
    background-color: #a28598;
    width: 37%;
}

.believeHighlightCont {
    width: 69%;
    background-color: #a28598;
}

.introText {
    width: 27%;
}

.loveText {
    width: 30%;
}

.believeText {
    width: 27%;
}

.aboutCont {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introCont {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin-bottom: 18%;
}

.loveCont {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin-bottom: 8%;
}

.believeCont {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin-bottom: 25%;
}

.introImg {
    width: 50%;
    box-shadow: 3px 3px 20px gray;
}

.loveImgs {
    position: relative;
    width: 48%;
}

.bouqetImg {
    width: 50%;
    position: absolute;
    bottom: 80%;
    right: 85%;
}

.brideFlowersImg {
    width: 100%;
}

.believeImgs {
    position: relative;
    width: 50%;
}

.sunsetCouple {
    width: 68%;
}

.couple {
    position: absolute;
    width: 68%;
    z-index: 1;
    top: 40%;
    left: 45%;
}

.weddingCouple, .brideFlowersMobile {
    display: none;
}

@media only screen and (max-width: 835px) {
    .aboutCont{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        font-size: 10.2vw;
    }
    
    .introCont, .loveCont, .believeCont {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 3%;
    }

    .introText, .loveText, .believeText {
        width: 82%;
        margin-left: 9%;
    }


    .highlightText {
        font-size: 6.1vw;
        line-height: 7.2vw;
    }

    .highlightCont {
        margin-bottom: 5%;
    }

    .introHighlightCont {
        width: 75%;
    }

    .loveHighlightCont {
        width: 28%;
    }

    .believeHighlightCont {
        width: 45%;
    }

    .mobileDesc {
        font-size: 5.1vw;
        display: block;
        line-height: 6.2vw;
    }

    .desc {
        display: none;
    }
    
    .introImg {
        box-shadow: 0px 0px 0px;
    }
    
    .introImg, .loveImgs, .believeImgs, .brideFlowersMobile {
        width: 100%;
    }

    .brideFlowersMobile {
        width: 105%;
        margin-left: -1%;
    }

    .bouqetImg, .couple, .sunsetCouple, .brideFlowersImg{
        display: none;
    }

    .weddingCouple, .brideFlowersMobile {
        display: block;
    }

    .scroll-to-top-container-about {
        display: none;
    }
}