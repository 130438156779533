@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.mobileView {
    display: flex;
    flex-direction: column;
    background-color: rgb(250,243,241);
}

#mobileHeader {
    font-family: 'Italiana';
    font-size: 2.5em;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 5vw;
}

.listElmM {
    display: flex;
    justify-content: center;
    align-items: center;
}
.servHeaderM {
    font-family: "Cormorant Garamond";
    font-style: Regular;
    font-size: 32px;
    line-height: 40px;
    line-height: 106%;
    align-self: left;
    vertical-align: Top;
    letter-spacing: 1%;
    color: rgba(252, 249, 234, 1);
    position: absolute;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: underline;
}
.servImgM {
    width: 100vw;
    margin-bottom: 20px;
    aspect-ratio: 1.4;
    object-position: 0 0;
    object-fit: cover;
    filter: brightness(0.7);
}

#bottomText {
    font-family: 'Cormorant Garamond';
    font-size: 1.125;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

#bottomButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
}

#arrowButton {
    width: 44.17px;
    position: absolute;
    margin-left: 80vw;
}

#contactButtonM {
    display: flex;
    background-color: rgba(162, 133, 152, 1);
    color: rgba(252, 249, 234, 1);
    font-family: "Cormorant Garamond";
    font-size: 1.25em;
    font-weight: 700;
    width: 138px;
    height: 47px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    font-style: bold;
    text-decoration: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 45px;
}

#topContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: "Cormorant Garamond";
    font-weight: 700;
    font-size: 1.1em;
    text-align: center;
}

#topTitle {
    margin: 0px;
    position: absolute;
    margin-bottom: 8px;
}

#backArrow {
    height: 20px;
    width: 9.95px;
    margin-right: 80vw;
}

#bottomContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mobileServDesc {
    font-family: 'Cormorant Garamond';
    font-size: 1.125em;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-align: left;
    width: 89vw;
}