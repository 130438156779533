@import url('https://fonts.googleapis.com/css?family=Italiana:wght@400&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&display=swap");

body {
    background-color: #FAF1EF;
}

.Gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-display,
.gallery-display-mobile {
    width: 100%;
    overflow: hidden;
}