.gallery-display-container {
    width: 100vw;
}

.gallery-category-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6.66em;
    margin: 4.313em 0 0 0;
}

.gallery-category {
    background: none;
    border: none;
    font-family: 'Cormorant Garamond';
    font-style: normal;
    font-size: 2.5em;
    text-align: center;
    transition: 0.87s;
    cursor: pointer;
}

.gallery-category:hover {
    transform: scale(1.2);
}

.active-category {
    font-weight: 700;
    text-decoration: underline 0.05em;
    text-underline-offset: 0.1em;
}

.inactive-category {
    font-weight: 500;
    filter: opacity(0.35);
}



/*

Added media queries to adjust the sizing of the gallery category texts and spacing
Allows the page to look more consistent when on smaller screens like tablet
*/
@media screen and (max-width: 835px) {
    .gallery-category-container{
        gap: 4.66em;
    }
}

@media screen and (max-width: 700px) {
    .gallery-category {
        font-size: 2em;
    }
    .gallery-category-container{
        gap: 3.66em;
    }
}
    
@media screen and (max-width: 600px) {
    .gallery-category {
        font-size: 1.8em;
    }
    .gallery-category-container{
        gap: 2em;
    }
}