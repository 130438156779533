.gallery-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
}

.divider {
    width: 60vw;
    height: 0;

    border: 1px solid #C4C4C4;
    margin: 4em 0 0 0;
}

.upper-gallery-carousel,
.lower-gallery-carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4em;
    align-items: center;
    justify-content: center;
    margin-top: 4em;
}