.review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review-text {
    height: fit-content;
    width: 100%;
    padding: 0 0 2.3% 0;

    font-family: 'Cormorant Garamond';
    font-style: normal;
    font-weight: bold;
    font-size: 2.98vw;
    line-height: 150%;
    text-align: center;
    color: #000000;
}

.review-user {
    margin: 1vh;

    font-family: 'Old Standard TT';
    font-style: italic;
    font-weight: lighter;
    font-size: 2.15vw;
    line-height: 2.26vw;
    text-align: center;

    color: #747474;
}

@media screen and (max-width: 1024px) {
    .review-text {
        font-size: 3.35vw;
    }

    .review-user {
        width: 100%;
        font-size: 2.5vw;
    }
}

@media screen and (max-width: 480px) {
    .review-text {
        width: 85%;
        font-size: 4.65vw;
    }

    .review-user {
        width: 100%;
        font-size: 4.15vw;
    }
}
