.forward-review-button, .backward-review-button {
    border: none;
    cursor: pointer;
    appearance: none;
    background-color: inherit;
    transition: 0.5s;
}

.back-button, .forward-button {
    height: 5.26vw;
}

.forward-review-button:hover, .backward-review-button:hover {
    transform: scale(1.2);
}

.review-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
}

.current-review-display {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
}

.review-dot-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: 2% 0 0 0;
}
.review-dot {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    background-color: #D9D9D9;
    margin: 0 0.5vw 0 0.5vw;
    transition: 0.5s;
}

.review-dot:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 2559px) {
    .current-review-display {
        width: 63%;
        min-height: 16.14vw;
    }
}

@media screen and (max-width: 1440px) {
    .current-review-display {
        width: 71%;
        min-height: 17.11vw;
    }
}

@media screen and (max-width: 1024px) {
    .back-button, .forward-button {
        height: 4.67vw;
    }

    .review-dot {
        width: 1.33vw;
        height: 1.33vw;
    }

    .current-review-display {
        width: 66%;
    }
    
}

@media screen and (max-width: 480px) {
    .back-button, .forward-button {
        height: 9.31vw;
    }

    .review-dot {
        width: 2.5vw;
        height: 2.5vw;

        margin: 0 0.89vw 0 0.89vw;
    }
    
    .current-review-display {
        width: 66%;
    }
}