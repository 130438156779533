@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Italiana&display=swap');

#servBar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 175px;
    background-color: rgb(221,204,209);
    /* justify-content: center; Alternative gap version */ 
    /* gap: 2vw; */
}

#barElm {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .7s;
    height: 100%;
}

#barElm:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.servImg {
    width: 14vw;
    height: 14vw;
    max-width: 223px;
    max-height: 140px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: 0 0;
    filter: brightness(0.5);
}

.servHeader {
    font-family: 'Italiana';
    font-style: Regular;
    font-size: 2em;
    line-height: 40px;
    line-height: 106%;
    align-self: left;
    vertical-align: Top;
    letter-spacing: 1%;
    color: rgba(252, 249, 234, 1);
    position: absolute;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.servHeader2 {
    font-family: 'Italiana';
    font-style: Regular;
    font-size: 1.625em;
    line-height: 40px;
    line-height: 106%;
    align-self: left;
    color: rgba(252, 249, 234, 1);
    position: absolute;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-wrap: break-word; 
}

@media screen and (max-width: 1200px) {
    .servHeader2 {
        font-size: 1.425em;
    }
}
@media screen and (max-width: 1025px) {
    .servHeader {
        font-size: 1.525em;
    }
    .servHeader2 {
        font-size: 1.225em;
    }
}

@media screen and (max-width: 870px) {
    .servHeader {
        font-size: 1.325em;
    }
    
    .servHeader2 {
        font-size: 1em;
    }
}