.gallery-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    text-align: center;
}

.upper {
    margin: 3.72em 0 3.83em 0;
}

.lower {
    margin: 3.33em 0 3.83em 0;
}

.gallery-title {
    font-family: 'Italiana';
    font-size: 3.56em;
    font-weight: 400;
}

.gallery-date {
    font-family: 'Cormorant Garamond';
    font-size: 1.57em;
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.5);
}

.gallery-desc {
    font-family: 'Cormorant Garamond';
    font-size: 1.57em;
    font-weight: 500;
    text-align: center;
}


/*adjusted sizing, widths, and spacing for texts to be displayed on mobile  */
@media screen and (max-width: 500px) {
    .upper {
        margin: 1.72em 0 2em 0;
    }

    .gallery-title {
        font-size: 2.5em;
        width: 78vw;
        text-align: left;
    }

    .gallery-date {
        width: 78vw;
        text-align: left;
        font-size: 1.125em;
        margin-bottom: 5px;
    }

    .gallery-desc {
        width: 78vw;
        text-align: left;
        font-size: 1.125em;
        line-height: 22px;
    }
}